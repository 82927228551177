import { useRef, CSSProperties, useEffect, useState, memo } from 'react';
import Head from 'next/head';
import Debug from 'debug';

import withSpacing, { WithSpacingProps } from '~/app/lib/hocs/withSpacing';
import { getAspect } from './utils';
import { ImageProps } from '.';

const debug = Debug('songwhip/BackgroundImage');

interface BackgroundImageProps extends ImageProps, WithSpacingProps {
  gradient?: string;
  withPreload?: boolean;
  backgroundColor?: string;
  imageOpacity?: number;
}

export const BackgroundImage = memo(
  withSpacing<BackgroundImageProps>(
    ({
      src,
      alt,
      size,
      className,
      style,
      objectPosition = '50% 50%',
      fadeInDuration = 1000,
      backgroundColor = '#000',
      borderRadius,
      gradient,
      withPreload,
      imageOpacity = 1,
      testId,
      imgStyle,
    }) => {
      const dynamicStyleRef = useRef<CSSProperties>({ opacity: 0 });
      const nodeRef = useRef<HTMLDivElement>(null);
      const [activeSrc, setActiveSrc] = useState(src);
      const aspect = getAspect(size);

      debug('render', src);

      const rootStyle: React.CSSProperties = {
        backgroundColor,
        ...style,
      };

      const innerStyle: React.CSSProperties = {
        ...imgStyle,
        position: 'relative',
        width: '100%',
        height: '100%',
        paddingBottom: aspect ? `${aspect * 100}%` : '',
        backgroundPosition: objectPosition,
        backgroundSize: 'cover',
        transitionProperty: 'opacity',
        transitionDuration: `${fadeInDuration}ms`,
        borderRadius,
        ...dynamicStyleRef.current,
      };

      // when the src changes gracefully fade-out then back in
      useEffect(() => {
        const srcChanged = src !== activeSrc;
        const style = nodeRef.current?.style;

        if (!srcChanged || !style) {
          debug('no changed');
          return;
        }

        debug('src changed', src);

        // fade out the current image
        style.opacity = '0';

        // set the new src as 'active' to trigger fade-in
        const timeout = setTimeout(() => {
          setActiveSrc(src);
        }, fadeInDuration);

        return () => {
          clearTimeout(timeout);
        };
      }, [src]);

      return (
        <div
          className={className}
          style={rootStyle}
          title={alt}
          data-testid={testId}
        >
          <div
            ref={nodeRef}
            style={{
              ...innerStyle,
              backgroundImage: toBackgroundImage({ src: activeSrc, gradient }),
            }}
          />
          <img
            {...{
              src: activeSrc,
              'aria-hidden': 'true',
              width: 0,
              height: 0,
              Onload: `this.previousElementSibling.style.opacity = ${imageOpacity}`,
            }}
          />
          {withPreload && (
            <Head>
              <link rel="preload" href={activeSrc} as="image" />
            </Head>
          )}
        </div>
      );
    }
  )
);

const toBackgroundImage = ({
  src,
  gradient,
}: {
  src: string | undefined;
  gradient?: string;
}) => {
  if (!src) return;

  if (gradient) return `${gradient}, url(${src})`;

  // hack to trick LCP into thinking it's a background image
  return `url(${src}), linear-gradient(0deg,rgba(0,0,0,.01) 0%,rgba(0,0,0,.01) 100%)`;
};

export default BackgroundImage;
