import { ServiceTypes } from '~/types';
import { SERVICES } from '../../getServiceDisplayData';
import openWindow from '../../utils/openWindow';

/**
 * Add referral param to qobuz url.
 */
export const resolveQobuzURL = (url: string) => {
  const SW_REF = 'sw';
  const parsedUrl = new URL(url);
  const { searchParams } = parsedUrl;

  searchParams.set(SW_REF, '1');

  return parsedUrl.toString();
};

const shouldHandle = (url: string) =>
  SERVICES[ServiceTypes.QOBUZ].urlPattern.test(url);

const openQobuz = (url: string) => {
  if (!shouldHandle(url)) return;

  const link = resolveQobuzURL(url);

  return openWindow(link);
};

export default openQobuz;
