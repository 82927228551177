import { memo, useMemo } from 'react';
import classNames from 'classnames';
import css from 'styled-jsx/css';

import Button, { ButtonProps } from '~/app/components/Button';
import LinkIcon from '~/app/components/Icon/LinkIcon';
import { Icon } from '~/app/components/Icon/toIcon';
import { ServiceTypes } from '~/types';

import getServiceDisplayData, {
  ServiceDisplayData,
} from '~/app/lib/getServiceDisplayData';

export const DEFAULT_HEIGHT_REM = 5.5;

const styles = css.resolve`
  .serviceButton :global(svg.icon) {
    color: #000 !important;
  }

  /* icon pre-layerized to avoid hover glitch in safari */
  @media (hover: hover) {
    .serviceButton :global(svg.icon) {
      /* REVIEW:PERF: this is creating a lot of layers, is it still
    required? Can it be changed to only target ios? */
      will-change: opacity;
    }
  }

  .serviceButton:focus-visible :global(svg.icon),
  .serviceButton:hover :global(svg.icon) {
    color: currentColor !important;
    opacity: 1 !important;
  }

  .serviceButton:focus-visible :global(.bitmapIcon),
  .serviceButton:hover :global(.bitmapIcon) {
    filter: none;
    opacity: 1 !important;
  }

  .withColoredIcon :global(.bitmapIcon) {
    filter: none;
  }
`;

export interface ServiceButtonProps
  extends Pick<
    ButtonProps,
    | 'onClick'
    | 'isLoading'
    | 'isDisabled'
    | 'testId'
    | 'margin'
    | 'height'
    | 'tabIndex'
    | 'href'
    | 'data'
  > {
  className?: string;

  /**
   * The button text
   */
  text?: string;

  /**
   * Explicitly define an `Icon` to use.
   */
  Icon?: Icon;

  /**
   * Always show a colored icon instead of just on hover.
   *
   * @default false
   */
  withColoredIcon?: boolean;

  /**
   * Define whether an `href` should be used on the button.
   * When in edit-mode we don't want to actually have `hrefs`.
   *
   * @default true
   */
  withHref?: boolean;
  renderAfter?: ButtonProps['renderAfter'];

  /**
   * The Service to infer text, color and icon from. This
   * isn't required as not all links/buttons map to a
   * built-in service.
   */
  serviceType?: ServiceTypes;
}

const DEFAULT_SERVICE: Omit<ServiceDisplayData, 'urlPattern'> = {
  name: '',
  Icon: LinkIcon,
  color: '#000',
};

/**
 * A button component that takes care of resolving styling based on the given,
 * `serviceType`. When no `serviceType` is given or you want to override the
 * default service name or Icon, you can provide props to overridde.
 */
const ServiceButton = memo<ServiceButtonProps>((props) => {
  const {
    height = `${DEFAULT_HEIGHT_REM}rem`,
    text,
    className,
    onClick,
    withColoredIcon,
    withHref = true,
    href,
    renderAfter,
    Icon: CustomIcon,
    serviceType,
    testId,
    ...buttonProps
  } = props;

  const service = getServiceDisplayData(serviceType) || DEFAULT_SERVICE;
  const buttonText = text || service?.name;

  // don't render anything if no button text resolved
  if (!buttonText) return null;

  const iconColor = service?.color;
  const Icon = CustomIcon || service?.Icon;
  const defaultIconColor = withColoredIcon ? iconColor : undefined;

  // COMPLEX: If the `dynamicColoring` flag is set this is a BitmapIcon/CustomBrand that has opted-in
  // to 'dynamic coloring'. When the the icon isn't colored all the time (via `withColoredIcon`)
  // then we set it to 'dark' as the button background is white.
  const coloring = Icon.dynamicColoring && !withColoredIcon && 'dark';

  // when the icon is not desaturated we lower the opacity slightly and
  // when it's hovered we bump it to 1 so that it 'pops' a little
  const defaultIconOpacity =
    withColoredIcon || (Icon.isBitmap && !Icon.dynamicColoring)
      ? 0.8
      : undefined;

  return (
    <>
      <Button
        text={buttonText}
        withBeforeHoverStyle={false}
        withBackgroundHoverStyle={false}
        href={withHref ? href : undefined}
        testId={`serviceButton ${serviceType || ''} ${testId || ''}`}
        className={classNames(className, 'serviceButton', styles.className, {
          withColoredIcon,
        })}
        height={height}
        onClick={onClick}
        renderAfter={renderAfter}
        textProps={useMemo(() => ({ weight: 'regular' }), [])}
        isNoFollow
        inNewTab
        withChevron
        isInverted
        style={{
          boxShadow:
            '0px 5px 15px rgba(0,0,0,0.1),0px 1px 1px rgba(0,0,0,0.2),0px 1px 4px rgba(0,0,0,0.25)',
          borderRadius: '1em',
        }}
        renderBefore={() =>
          Icon && (
            <div style={{ color: iconColor }}>
              <Icon
                className="icon"
                color={defaultIconColor}
                opacity={defaultIconOpacity}
                coloring={coloring}
                size="0.5em"
                margin="0 0 0 0.02em"
              />
            </div>
          )
        }
        {...buttonProps}
      />
      {styles.styles}
    </>
  );
});

export default ServiceButton;
