import { useRef, RefObject } from 'react';
import useIsOverflowing from './useIsOverflowing';

const IsOverflowing = ({
  fallbackValue,
  render,
}: {
  fallbackValue: boolean;
  render: (params: {
    rootRef: RefObject<HTMLElement>;
    targetRef: RefObject<HTMLElement>;
    isOverflowing: boolean;
  }) => JSX.Element;
}) => {
  const targetRef = useRef<HTMLElement>(null);
  const rootRef = useRef<HTMLElement>(null);
  const isOverflowing = useIsOverflowing({ targetRef, rootRef, fallbackValue });

  return render({ rootRef, isOverflowing, targetRef });
};

export default IsOverflowing;
