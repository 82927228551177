import BlogIcon from '~/app/components/Icon/BlogIcon';
import ChartIcon from '~/app/components/Icon/ChartIcon';
import CodeIcon from '~/app/components/Icon/CodeIcon';
import DateIcon from '~/app/components/Icon/DateIcon';
import EditIcon from '~/app/components/Icon/EditIcon';
import EmailIcon from '~/app/components/Icon/EmailIcon';
import FlagIcon from '~/app/components/Icon/FlagIcon';
import FlashIcon from '~/app/components/Icon/FlashIcon';
import HeadphonesIcon from '~/app/components/Icon/HeadphonesIcon';
import HeartIcon from '~/app/components/Icon/HeartIcon';
import HomeIcon from '~/app/components/Icon/HomeIcon';
import InfoIcon from '~/app/components/Icon/InfoIcon';
import LinkIcon from '~/app/components/Icon/LinkIcon';
import LockIcon from '~/app/components/Icon/LockIcon';
import MoneyIcon from '~/app/components/Icon/MoneyIcon';
import PhoneIcon from '~/app/components/Icon/PhoneIcon';
import PlayIcon from '~/app/components/Icon/PlayIcon';
import SearchIcon from '~/app/components/Icon/SearchIcon';
import ShareIcon from '~/app/components/Icon/ShareIcon';
import ShoppingBagIcon from '~/app/components/Icon/ShoppingBagIcon';
import SongIcon from '~/app/components/Icon/SongIcon';
import SparkleIcon from '~/app/components/Icon/SparkleIcon';
import StarIcon from '~/app/components/Icon/StarIcon';
import TicketIcon from '~/app/components/Icon/TicketIcon';
import TickIcon from '~/app/components/Icon/TickIcon';
import Verified2Icon from '~/app/components/Icon/Verified2Icon';
import WhatsappIcon from '~/app/components/Icon/WhatsappIcon';
import WorldIcon from '~/app/components/Icon/WorldIcon';

export const ICONS = {
  shop: ShoppingBagIcon,
  search: SearchIcon,
  sparkle: SparkleIcon,
  star: StarIcon,
  email: EmailIcon,
  home: HomeIcon,
  money: MoneyIcon,
  play: PlayIcon,
  lock: LockIcon,
  tick: TickIcon,
  headphones: HeadphonesIcon,
  blog: BlogIcon,
  flash: FlashIcon,
  share: ShareIcon,
  song: SongIcon,
  world: WorldIcon,
  heart: HeartIcon,
  linkIcon: LinkIcon,
  info: InfoIcon,
  phone: PhoneIcon,
  ticket: TicketIcon,
  verified: Verified2Icon,
  chart: ChartIcon,
  edit: EditIcon,
  code: CodeIcon,
  date: DateIcon,
  flag: FlagIcon,
  whatsapp: WhatsappIcon,
};

export type IconKey = keyof typeof ICONS;
