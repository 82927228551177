import { LayoutData } from '../../types';
import { resolveDataValue } from '../lib';

export const resolveText = ({
  text,
  layoutData,
}: {
  text: string | undefined;
  layoutData: LayoutData;
}) => {
  const isDataPath = text && /^\$\./.test(text);
  return isDataPath ? resolveDataValue(layoutData, text) : text;
};
