import { PageSectionPreset } from '../../types';
import { PageSectionTypes } from '../types';
import { IconLinksProps } from './types';

export const toSocialLinksSectionPreset = ({
  shareId,
  ...props
}: {
  title: string;
  navTitle: string;
  shareId?: string;
}): PageSectionPreset<IconLinksProps> => ({
  component: PageSectionTypes.ICON_LINKS,
  shareId,

  props: {
    ...props,
    sectionId: 'follow',

    links: [
      '$.item.links.twitter',
      '$.item.links.facebook',
      '$.item.links.instagram',
      '$.item.links.youtube',
      '$.item.links.tiktok',
    ],
  },
});
