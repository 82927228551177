import { PageSectionPreset } from '../../types';
import { PageSectionTypes } from '../types';
import { BlockTextProps } from './types';

export const toDescriptionSectionPreset = ({
  displayName,
  ...props
}: {
  title: string;
  navTitle: string;
  displayName: string;
  text: string | undefined;
}): PageSectionPreset<BlockTextProps> => {
  return {
    component: PageSectionTypes.BLOCK_TEXT,
    displayName,

    props: {
      ...props,
      sectionId: 'about',
    },
  };
};
