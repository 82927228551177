import { CSSProperties, memo } from 'react';

import {
  PageSectionDefinition,
  PageSectionPreset,
  ItemContext,
  ResolvedPageSection,
} from '../types';

import { PageSectionComponent } from '../sections/types';

export const PAGE_SECTION_CLASS = 'pageSection';

export interface SectionRendererProps {
  itemContext: ItemContext;
  pageSection: PageSectionDefinition<any>;
  sectionPath: string;
  sectionIndex: number;
  components: Record<string, PageSectionComponent>;
  style?: CSSProperties;
}

const SectionRenderer = memo<SectionRendererProps>(
  ({
    itemContext,
    components,
    pageSection,
    sectionPath,
    sectionIndex,
    style,
    ...restProps
  }) => {
    const resolved = resolvePageSection({
      itemContext,
      pageSection,
    });

    const Component = resolved?.component && components[resolved.component];
    if (!Component) return null;

    const props = {
      // default section id to ensure the ItemNav works
      sectionId: `section${sectionIndex}`,

      ...resolved!.props,
      ...restProps,

      sectionPath,
      sectionIndex,
    };

    return (
      <section
        id={props.sectionId}
        className={PAGE_SECTION_CLASS}
        data-testid="pageSection"
        style={{
          // show section top 20% down the viewport when scrolled to via <ItemPageNav>
          scrollMarginTop: '20vh',

          ...style,
        }}
      >
        <Component {...props} />
      </section>
    );
  }
);

export const resolvePageSection = ({
  pageSection,
  itemContext,
}: Pick<SectionRendererProps, 'pageSection' | 'itemContext'>):
  | ResolvedPageSection
  | undefined => {
  const { preset: presetId, shared, ...restPageSection } = pageSection;

  // when the layout item is references a shared component
  if (shared) {
    // find the shared item in the ItemContext that matches the id
    const sharedItem = itemContext.shared?.[shared];

    // Oops couldn't find a matching shared item in the layout context,
    // this can happen if the shared component is deleted at source.
    // We can remove this broken reference on next Edit Page save.
    if (!sharedItem) return;

    // resolve the layout item using the localContext and the share item
    return sharedItem;
  }

  const preset =
    presetId && (itemContext.presets[presetId] as PageSectionPreset);

  // a merge of the pageSection and the defined preset
  return {
    ...preset,
    ...restPageSection,

    props: {
      ...preset?.props,
      ...restPageSection?.props,
      layoutData: itemContext.data,
    },
  };
};

export default SectionRenderer;
