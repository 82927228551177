import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useDebounce from '~/app/lib/hooks/useDebounce';

interface NavItem {
  id: string;
  index: number;
  text: string;
}

interface ItemPageNavContextValue {
  getItems?: () => NavItem[];
  register?: (params: NavItem) => void;
  unregister?: (id: string) => void;
}

const ItemPageNavContext = createContext<ItemPageNavContextValue>({});

export const useItemPageNav = () => {
  return useContext(ItemPageNavContext);
};

export const useRegisterNavItem = ({
  text,
  id,
  index,
}: {
  id: string;
  index: number;
  text?: string;
}) => {
  const { register, unregister } = useContext(ItemPageNavContext);

  useMemo(() => {
    if (!(register && unregister && text)) {
      return;
    }

    register({
      id,
      text,
      index,
    });
  }, []);

  useMemo(() => {
    return () => {
      if (unregister && id) {
        unregister(id);
      }
    };
  }, []);
};

export const ItemPageNavProvider: FC = ({ children }) => {
  const itemsRef = useRef<Record<string, NavItem>>({});
  const isMountedRef = useRef(false);
  const [state, setState] = useState({});
  const forceUpdate = useDebounce(() => setState({}), 100);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <ItemPageNavContext.Provider
      value={useMemo<ItemPageNavContextValue>(
        () => ({
          getItems: () =>
            Object.values(itemsRef.current).sort((a, b) =>
              a.index < b.index ? -1 : 1
            ),

          register: ({ id, text, index }: NavItem) => {
            // We must use ref here instead of state as this function
            // is called during ssr where state changes are forbidden.
            // We create a new array each time to ensure downstream
            // memoized renders refresh.
            itemsRef.current[id] = { id, text, index };
            // itemsRef.current = [...itemsRef.current, { id, text }];

            // COMPLEX: if a section mounts late (ie. lazy-loaded) then we must
            // force the context value to change so that ItemPageNav will rerender
            // and show the new nav item. This is only the case when pages are
            // client-side rendered, for ssr pages all the lazy chunks are loaded
            // upfront, ready for the first hydration.
            if (isMountedRef.current) {
              forceUpdate();
            }
          },

          unregister: (id) => {
            delete itemsRef.current[id];

            // COMPLEX: if a section mounts late (ie. lazy-loaded) then we must
            // force the context value to change so that ItemPageNav will rerender
            // and show the new nav item. This is only the case when pages are
            // client-side rendered, for ssr pages all the lazy chunks are loaded
            // upfront, ready for the first hydration.
            if (isMountedRef.current) {
              forceUpdate();
            }
          },
        }),
        [state]
      )}
    >
      {children}
    </ItemPageNavContext.Provider>
  );
};
