import { ItemTypes } from '~/types';

import mapCustomPage from './mapCustomPage';
import mapArtist from './mapArtist';
import mapAlbum from './mapAlbum';
import mapTrack from './mapTrack';

export * from './types';

const mapper = (payload) => {
  switch (payload.type) {
    case ItemTypes.ARTIST:
      return mapArtist(payload);
    case ItemTypes.ALBUM:
      return mapAlbum(payload);
    case ItemTypes.TRACK:
      return mapTrack(payload);
    case ItemTypes.CUSTOM_PAGE:
      return mapCustomPage(payload);
    default:
      throw new Error(`unsupported type: ${payload.type}`);
  }
};

export default mapper;
