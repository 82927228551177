import { selectArtistGlobal } from '~/app/lib/store/artists/selectors';
import { State } from '~/app/lib/store/types';
import { useSelector } from '~/app/lib/store/redux';

const useSelectArtist = (artistId: number | undefined) => {
  return useSelector((state: State) =>
    artistId ? selectArtistGlobal(state, artistId) : undefined
  );
};

export default useSelectArtist;
