import resolveSortedBuyLinks from '~/app/lib/utils/resolveLinksForCountry/lib/resolveSortedBuyLinks';
import resolveSortedStreamLinks from '~/app/lib/utils/resolveLinksForCountry/lib/resolveSortedStreamLinks';

import { PageSectionPreset } from '../../types';
import { PageSectionTypes } from '../types';
import { ItemLinksProps } from './types';

export const toStreamLinksSectionPreset = ({
  country,
  title,
  navTitle,
}: {
  country: string;
  title: string;
  navTitle?: string;
}): PageSectionPreset<ItemLinksProps> => ({
  component: PageSectionTypes.ITEM_LINKS,

  props: {
    title,
    navTitle,
    sectionId: 'stream',
    withShowMore: true,
    withColoredIcons: false,

    links: resolveSortedStreamLinks(country).map(
      (key) => `$.item.links.${key}`
    ),
  },
});

export const toBuyLinksSectionPreset = ({
  title,
  navTitle,
}: {
  title: string;
  navTitle?: string;
}): PageSectionPreset<ItemLinksProps> => {
  return {
    component: PageSectionTypes.ITEM_LINKS,

    props: {
      title,
      navTitle,
      sectionId: 'buy',
      withShowMore: true,
      withColoredIcons: false,
      links: resolveSortedBuyLinks().map((key) => `$.item.links.${key}`),
    },
  };
};
