import { ITUNES_AFFILIATE_TOKEN } from '~/config';
import { isIos } from '../../device/utils';
import openWindow from '../../utils/openWindow';

const ITUNES_PATTERN = /(itunes|music)\.apple\.com/;

const shouldHandle = (url: string) => ITUNES_PATTERN.test(url);

/**
 * Opens links in AppleMusic or legacy iTunes Store apps.
 *
 * Desktop all:
 *  - Goes to music.apple.com where Apple thens triggers native Music.app
 *  - Adding the app=itunes param opens a different `musics://` store page in Music.app showing prices
 *  - Adding the ls=1 param triggers music.apple.com to attempt opening native app
 *  - You can trigger the Music.app to open using the musics:// protocol but we want to
 *    hit music.apple.com to ensure affiliate token registered and also lean on apple.com
 *    for native app opening logic.
 *
 * Mobile ios w/ app=itunes:
 *  - Use `itmss://` to open native 'iTunes Store' app
 *  - Opening default music.apple.com never shows pricing
 *  - There doesn't seem to be a mobile equivalent of desktop 'Music' app pricing page
 *
 * Mobile w/ app=music:
 *  - Navigate to music.apple.com and let device open relevant app
 *  - We want to hit music.apple.com to ensure affiliate token is registered
 */
const openAppleMusic = (url: string, artistName: string) => {
  if (!shouldHandle(url)) return;

  const parsedUrl = new URL(url);
  const app = parsedUrl.searchParams.get('app');
  const isItunes = app === 'itunes';

  // default to music app if not given
  if (!app) {
    parsedUrl.searchParams.append('app', 'music');
  }

  // add the affiliate token
  parsedUrl.searchParams.append('at', ITUNES_AFFILIATE_TOKEN);

  // add the campaign name (we use the artist name as the campaign name)
  parsedUrl.searchParams.append('ct', artistName);

  // trigger music.apple.com to attempt to open native app
  parsedUrl.searchParams.append('ls', '1');

  // If it's an itunes link and the device is ios then the only way to
  // show the store pricing page is to open the native 'iTunes Store' app.
  // I'm yet to find another way to display pricing/store on ios in Music.app or web.
  if (isItunes && isIos(navigator.userAgent)) {
    openItunes(parsedUrl.toString());
    return true;
  }

  // geo prefix helps with some link localization I think
  parsedUrl.host = `geo.${parsedUrl.host}`;

  return openWindow(parsedUrl.toString());
};

/**
 * Attempts to open legacy ios 'iTunes Store' app.
 */
const openItunes = (appleMusicUrl: string) => {
  const itunesNativeUrl = appleMusicUrl
    // magic itunes protocol to trigger native app open
    .replace(/https:/, 'itmss:')

    // music.apple.com -> itunes.apple.com
    .replace('//music.', '//itunes.');

  location.href = itunesNativeUrl;
};

export default openAppleMusic;
