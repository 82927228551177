import { AddFeatureItem } from '~/app/components/ItemPage/ItemPageEdit/AddFeatureDialog/constants';
import { I18nFormatters } from '~/app/lib/i18n';

export enum ShareIds {
  SOCIAL_LINKS = 'ARTIST_SOCIAL_LINKS',
}

export const ADD_SHARED_ITEM_DATA = ({
  i18n,
}: {
  i18n: I18nFormatters;
}): Record<
  string,
  {
    title: AddFeatureItem['title'];
    description: AddFeatureItem['description'];
    shouldShow?: AddFeatureItem['shouldShow'];
  }
> => ({
  [ShareIds.SOCIAL_LINKS]: {
    title: i18n.t('item.sharedSocialLinksTitle'),
    description: i18n.t('item.sharedSocialLinksDescription'),
  },
});
