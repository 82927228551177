import PageError, { PageErrorProps } from '../PageError';
import { AppError } from '~/app/lib/errors/types';
import PageMetadata from '../PageMetadata';
import PageLoading from '../PageLoading';
import PageHeader from '../PageHeader';
import Page from '../Page';

interface PagePlaceholderProps {
  isLoading?: boolean | undefined;
  error?: AppError;
  withMenuButton?: boolean;
  toErrorText?: PageErrorProps['toText'];
}

const PagePlaceholder: React.FC<PagePlaceholderProps> = ({
  isLoading = false,
  withMenuButton,
  error,
  toErrorText,
}) => {
  return (
    <Page trackPageView={false}>
      <PageHeader withMenuButton={withMenuButton} />
      {isLoading && <PageLoading />}
      <PageMetadata title={error ? 'Error' : 'Loading …'} />
      {error && (
        <PageError testId="pageError" error={error} toText={toErrorText} />
      )}
    </Page>
  );
};

export default PagePlaceholder;
