import { RefObject } from 'react';
import { usePageTheme } from '../ItemPageEdit/addons/theme/PageThemeContext';
import ImageBackground from './ImageBackground';

const BackgroundContainer = ({
  nodeRef,
  height,
}: {
  nodeRef: RefObject<HTMLDivElement>;
  height: string | number;
}) => {
  const pageTheme = usePageTheme();

  return (
    <div
      style={{
        position: 'relative',
        background: pageTheme.backgroundColor,
        overflow: 'hidden',
        height: '100%',
      }}
    >
      <div
        ref={nodeRef}
        style={{
          position: 'relative',
          willChange: 'opacity,transform',
          overflow: 'hidden',
          height,
        }}
      >
        <ImageBackground />
      </div>
    </div>
  );
};

export default BackgroundContainer;
