import songwhipApi, { SongwhipApiOptions } from '../songwhipApi';

import mapper from '~/app/lib/songwhipApi/mapper';

export const resolveItemApi = async ({
  path,
  ...options
}: { path: string } & SongwhipApiOptions) => {
  const {
    json: { data },
    fromApiCache,
  } = await songwhipApi(`resolve/${encodeURI(path)}`, options);

  return { data: mapper(data), fromApiCache };
};
