import { useCallback, memo, CSSProperties } from 'react';

import useOpenExternalService from '~/app/lib/hooks/useOpenExternalService';
import { useEmitter } from '~/app/lib/hooks/useEmitter';
import LinkIcon from '~/app/components/Icon/LinkIcon';
import { Icon } from '~/app/components/Icon/toIcon';
import Clickable, { ClickableProps } from '~/app/components/Clickable';
import { usePageTheme } from '../../ItemPageEdit/addons/theme/PageThemeContext';
import { toRgba } from '~/app/lib/utils/color';

const IconLinkButton = memo<
  {
    Icon?: Icon;
    name?: string;
    link: string;
    size?: string | number;
    onClick?: (params: { event: MouseEvent; data: any }) => void;
    renderLink?: boolean;
    clickData?: any;
    testId?: string;
    style?: CSSProperties;
  } & ClickableProps
>(
  ({
    Icon = LinkIcon,
    name,
    link,
    onClick,
    clickData,
    renderLink = true,
    testId = 'iconButton',
    size = '5.2rem',
    style,
    ...clickableProps
  }) => {
    if (!name) return null;

    const pageTheme = usePageTheme();
    const openExternalService = useOpenExternalService();
    const emitter = useEmitter();

    const onClickInternal = useCallback(
      ({ event }) => {
        event.preventDefault();

        if (onClick) {
          onClick({ event, data: clickData });
          return;
        }

        // emit event so arbitrary parts of the app can respond (eg. fb pixel)
        emitter.emit('openExternalService', {
          name,
          url: link,
        });

        openExternalService(link);
      },
      [link]
    );

    return (
      <Clickable
        testId={testId}
        onClick={onClickInternal}
        href={renderLink ? link : undefined}
        margin="0 0.1em"
        title={name}
        style={{
          color: toRgba(pageTheme.textColor, 0.9),
          fontSize: size,
          ...style,
        }}
        {...clickableProps}
      >
        <Icon
          size="1em"
          className="Icon"
          coloring={Icon.dynamicColoring && 'light'}
        />
      </Clickable>
    );
  }
);

export default IconLinkButton;
