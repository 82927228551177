// Regex explained: https://regexr.com/58j0k
const toPathArray = (path: string) => path.match(/([^[.\]])+/g);

export const unset = (obj, path) => {
  const pathArray = toPathArray(path);

  pathArray?.reduce((acc, key, i) => {
    if (i === pathArray.length - 1) {
      if (Array.isArray(acc)) acc.splice(Number(key), 1);
      else delete acc[key];
    }

    return acc[key];
  }, obj);
};

export const set = (obj: any, path: string, value: any) => {
  const pathArray = toPathArray(path);

  pathArray?.reduce((acc, key, i) => {
    if (acc[key] === undefined) acc[key] = {};
    if (i === pathArray.length - 1) acc[key] = value;
    return acc[key];
  }, obj);
};

export const get = (obj: any, path: string, defValue?: any) => {
  // If path is not defined or it has false value
  if (!path) return undefined;

  const pathArray = toPathArray(path);

  // Find value if exist return otherwise return undefined value;
  return (
    pathArray?.reduce((prevObj, key) => prevObj && prevObj[key], obj) ||
    defValue
  );
};
