import { useCallback, MutableRefObject, RefObject } from 'react';

import { isGreatAtAnimations } from '~/app/lib/device/utils';

/**
 * Handles the buttery smooth background zoom/fade transitions on scroll.
 *
 * This had been very highly optimized so be VERY careful when editing
 * any of this code as it can run 100s of times per second. Be sure to use
 * devtools Performance timeline and Layers panel to ensure no layout,
 * layerization or delayerization is triggered on scroll.
 */
const useOnScrollCallback = ({
  imageRef,
  titleRef,
  headerContentRef,
}: {
  imageRef: RefObject<HTMLElement>;
  titleRef: MutableRefObject<HTMLElement | undefined>;
  headerContentRef?: RefObject<HTMLDivElement>;
}) => {
  const shouldScaleImage =
    process.browser && isGreatAtAnimations(navigator.userAgent);

  return useCallback(({ y }) => {
    // stop ios over-scroll glitch
    if (y < 0) return;

    const maxScrollY = window.innerHeight * 0.66;
    const yClamped = Math.min(y, maxScrollY);
    const ratio = Math.round((yClamped / maxScrollY) * 1000) / 1000;

    if (imageRef.current) {
      if (shouldScaleImage) {
        imageRef.current.style.transform = `scale(${
          Math.round((1 + ratio * 0.07) * 1000) / 1000
        })`;
      }

      // prevent event transitioning opacity to 1 or 0 as firefox will
      // un-lazyerize causing a paint when it's shown again
      imageRef.current.style.opacity = String(clamp(1 - ratio));
    }

    // prevent event transitioning opacity to 1 or 0 as firefox will
    // un-lazyerize causing a paint when it's shown again
    if (titleRef.current) {
      titleRef.current.style.opacity = String(
        clamp(Math.round((1 - ratio * 4) * 100) / 100)
      );
    }

    if (headerContentRef?.current) {
      headerContentRef.current.style.opacity = String(clamp(1 - ratio * 1.6));
    }
  }, []);
};

const clamp = (value: number) => Math.min(0.999, Math.max(value, 0.001));

export default useOnScrollCallback;
