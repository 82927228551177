import { PageSectionPreset } from '../../types';
import { PageSectionTypes } from '../types';
import { ShowsSectionItem, ShowsSectionProps } from './types';

export const toShowsSectionPreset = (props: {
  title: string;
  navTitle: string;
  items: ShowsSectionItem[] | undefined;
  geolocation: ShowsSectionProps['geolocation'];
}): PageSectionPreset<ShowsSectionProps> => {
  return {
    component: PageSectionTypes.SHOWS,

    props: {
      ...props,
      sectionId: 'shows',
    },
  };
};
