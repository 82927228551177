import { LinkLocalized } from '~/app/lib/utils/resolveLinksForCountry/lib/localizeLinks';
import { resolveServiceDataFromUrl } from '~/app/lib/getServiceDisplayData';
import { ServiceTypes } from '~/types';
import { get } from '~/app/lib/utils/objectPathUtils';

import { ItemLinksCustomLink, ResolvedLink } from '../types';
import { LayoutData } from '../../types';
import { IconKey, ICONS } from './icons';
import { ItemPageCustomLinkObject } from '~/app/lib/songwhipApi/types';

const DATA_REF_PATTERN = /^\$\.(.+)/;

export const resolveDataValue = <T = any>(
  data: LayoutData,
  key: string
): T | undefined => {
  if (typeof key !== 'string') return key;

  const matches = key.match(DATA_REF_PATTERN);
  const dataPath = matches && matches[1];

  if (!dataPath) return;

  const result = get(data, dataPath);

  // if the result is another reference then follow it
  if (typeof result === 'string' && DATA_REF_PATTERN.test(result)) {
    return resolveDataValue(data, result);
  }

  return result;
};

/**
 * HACK: infer whether link is default/custom by detecting
 * if it has the 'localized' key added in resolveLinksForCountry()
 */
const isDefaultLink = (link: ItemLinksCustomLink | LinkLocalized) =>
  'localized' in link;

export const resolveLink = (
  data: LayoutData,
  dataPath: string
): ResolvedLink | undefined => {
  const value = resolveDataValue<
    ItemPageCustomLinkObject<IconKey> | LinkLocalized
  >(data, dataPath);

  if (!value?.link) return;

  const brands = data.item.primaryOwnerAccount?.config?.brands;
  const service = resolveServiceDataFromUrl(value.link, brands);
  const icon = 'icon' in value ? value.icon : undefined;
  const Icon = icon ? ICONS[icon] : service.Icon;

  // REVIEW: We already resolve service name/text inside <ItemLinks>
  // do we need to resolve it here too?
  const text = 'text' in value ? value.text : service?.name;

  if (!text) {
    // eslint-disable-next-line no-console
    console.warn('failed to resolve link text', value);
    return;
  }

  return {
    type: 'link',
    ...value,
    text,
    Icon,
    icon,
    isDefault: isDefaultLink(value),
    serviceType: service.key as ServiceTypes,
    service,
    dataPath,
  };
};

export const resolveLinks = ({
  links,
  layoutData,
}: {
  links: string[];
  layoutData: LayoutData;
}) => {
  const result = links
    ?.map((dataPath) => resolveLink(layoutData, dataPath))
    .filter(Boolean) as ResolvedLink[];

  return result.length ? result : undefined;
};
