import React from 'react';

import useTheme from '~/app/lib/hooks/useTheme';
import Box from '~/app/components/Box';

import { PageSectionComponent } from '../types';
import { useRegisterNavItem } from '../../ItemPageNav';
import IconLinkButton from '../lib/IconLinkButton';
import SectionTitle from '../lib/SectionTitle';
import { resolveLinks } from '../lib';
import { IconLinksProps } from './types';

// TODO: rename this IconLinksSection
const IconLinks: PageSectionComponent<IconLinksProps> = ({
  title,
  links,
  layoutData,
  sectionId = 'follow',
  navTitle = 'Follow',
  sectionIndex,
}) => {
  const theme = useTheme();

  const linksResolved = resolveLinks({ layoutData, links });
  // unable to resolve any links from given props
  if (!linksResolved) return null;

  useRegisterNavItem({
    id: sectionId,
    text: navTitle,
    index: sectionIndex,
  });

  return (
    <div data-testid="iconLinks">
      <SectionTitle margin="0 0 2.3rem" text={title} />
      <Box flexRow centerContent padding="0 1.2rem">
        <div className="icons" style={{ color: theme.textColor90 }}>
          {linksResolved.map((resolved) => {
            return (
              <IconLinkButton
                key={resolved.link}
                Icon={resolved.Icon || resolved.service.Icon}
                link={resolved.link}
                name={resolved.text}
                size="5rem"
                margin="0 .13em"
              />
            );
          })}
        </div>
      </Box>
      <style jsx>{`
        .icons {
          display: flex;
        }

        .icons :global(.Icon) {
          transition: opacity 0.6s;
        }

        .icons:hover :global(.Icon) {
          opacity: 0.5;
        }

        .icons :global(.Icon:hover) {
          opacity: 1;
          transform: scale(1.02);
          color: currentColor !important;
        }
      `}</style>
    </div>
  );
};

export default IconLinks;
