import React, { FC } from 'react';
import BackgroundImage from '~/app/components/Image/BackgroundImage';
import useIsLargeScreen from '~/app/lib/hooks/useIsLargeScreen';
import { useI18n } from '~/app/lib/i18n';
import toSizedImageUrlNext from '~/app/lib/toSizedImageUrlNext';
import { toRgba } from '~/app/lib/utils/color';

import {
  IMAGE_QUALITY,
  IMAGE_WIDTH_LARGE,
  IMAGE_WIDTH_SMALL,
} from '../../constants';
import { useItemContext } from '../../ItemPageContext';
import { usePageTheme } from '../../ItemPageEdit/addons/theme/PageThemeContext';

const ImageBackground: FC = () => {
  const isLargeScreen = useIsLargeScreen();
  const itemContext = useItemContext();
  const { t } = useI18n();
  const defaultImage = itemContext.data.item.image;
  const pageTheme = usePageTheme();

  // if a special large-screen image is defined we'll use that,
  // else we just use the default image.
  const largeScreenImage =
    itemContext.addons.CUSTOM_IMAGE?.desktop?.url || defaultImage;

  // when device is large-screen, use the large-screen image
  const imageUrl = isLargeScreen ? largeScreenImage : defaultImage;

  const sizedUrl =
    imageUrl &&
    toSizedImageUrlNext({
      url: imageUrl,
      width: isLargeScreen ? IMAGE_WIDTH_LARGE : IMAGE_WIDTH_SMALL,
      quality: IMAGE_QUALITY,
    });

  const gradient = isLargeScreen
    ? `linear-gradient(0deg, ${toRgba(
        pageTheme.backgroundColor,
        0.98
      )} 0%,${toRgba(pageTheme.backgroundColor, 0.15)} 100%)`
    : ``;

  if (!sizedUrl) return null;

  return (
    <BackgroundImage
      testId="backgroundImage"
      src={sizedUrl}
      alt={t('item.pageBackgroundImageAlt')}
      objectPosition="50% 40%"
      gradient={gradient}
      fadeInDuration={400}
      imgStyle={{
        filter: 'saturate(.92)',
      }}
      style={{
        WebkitMaskImage: !isLargeScreen
          ? `linear-gradient(rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 100%)`
          : '',
      }}
      withPreload
      coverParent
      cover
    />
  );
};

export default ImageBackground;
