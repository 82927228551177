import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const LoadingObserverContext = createContext({
  register: () => {},
  unregister: () => {},
  totalLoading: 0,
});

export const useLoadingObserver = () => useContext(LoadingObserverContext);

/**
 * Create a loading state component to be used in conjunction with next/dynamic.
 * When the `<ObservedLoading>` mounts it registers with `<LoadingObserver>` ancestor
 * which keeps track of how many descendant components are loading.
 *
 * This means we can delay showing the `<ItemPageNav>` until all dynamic components
 * have finished loading. This is important as theses dynamic PageSectionComponents
 * register their navigation items on first render.
 */
export const toObservedLoadingComponent = () => {
  const ObservedLoading = () => {
    const { register, unregister } = useLoadingObserver();

    useEffect(() => {
      register();

      return () => {
        unregister();
      };
    }, []);

    return null;
  };

  return ObservedLoading;
};

/**
 * A context that descendant <ObservedLoading> can register/unregister with.
 * Its keeps track of how many dynamic descendent components are loading.
 * <ItemPageNav> will then use `useLoadingObserver()` to see `totalLoading`
 * and know when to reveal the navigation. This is all to avoid the navigation
 * from flashing and changing as the page progressively loads and hydrates.
 */
export const LoadingObserver: FC<{
  /**
   * Called on first-mount and then each time the `totalLoading` value changes.
   */
  onChange?: (params: { totalLoading: number }) => void;
}> = ({ children, onChange = () => {} }) => {
  const parentContext = useLoadingObserver();
  const [totalLoading, setTotalLoading] = useState(0);

  useEffect(() => {
    onChange({ totalLoading });
  }, [totalLoading]);

  return (
    <LoadingObserverContext.Provider
      value={useMemo(() => {
        return {
          register: () => {
            setTotalLoading((prevValue) => {
              return prevValue + 1;
            });

            parentContext.register();
          },

          unregister: () => {
            setTotalLoading((prevValue) => {
              return prevValue - 1;
            });

            parentContext.unregister();
          },

          totalLoading,
        };
      }, [totalLoading])}
    >
      {children}
    </LoadingObserverContext.Provider>
  );
};

/**
 * A wrapper component that shows a loading spinner when descendent
 * next/dynamic components are loading.
 *
 * The component must be mounted initially to trigger the js chunk
 * load but we show a loading spinner until we detect all components are loaded.
 *
 * Descendent dynamic components must use `toObservedLoadingComponent()`
 * when they're defined using next/dynamic and have an ancestor <LoadingObserver>
 * instance as this listening behavior isn't baked in by default.
 *
 * TODO: implement this
 */
// export const DynamicLoadingIndicator: FC<{ children: ReactNode }> = ({
//   children,
// }) => {};
