import BlockText from './sections/BlockText';
import IconLinks from './sections/IconLinks';
import ItemLinks from './sections/ItemLinks';
import { PageSectionTypes } from './sections/types';

export const MAX_CONTENT_WIDTH = '48rem';
export const IMAGE_WIDTH_LARGE = 1200;
export const IMAGE_WIDTH_SMALL = 750;
export const IMAGE_QUALITY = 60;

export enum LayoutSlotIds {
  BACKGROUND = 'background',
  HERO = 'hero',
  MAIN = 'main',
}

export enum PresetTypes {
  STREAM_LINKS = 'STREAM_LINKS',
  BUY_LINKS = 'BUY_LINKS',
  SOCIAL_LINKS = 'SOCIAL_LINKS',
  DESCRIPTION = 'DESCRIPTION',
  PRESAVE_BUTTONS = 'PRESAVE_BUTTONS',
  SUBSCRIBE_TO_RELEASE_BUTTONS = 'SUBSCRIBE_TO_RELEASE_BUTTONS',
  RELEASES = 'RELEASES',
  VIDEOS = 'VIDEOS',
  SHOWS = 'SHOWS',
  MERCH = 'MERCH',
  CAROUSEL = 'CAROUSEL',
}

export const CORE_SECTION_COMPONENTS = {
  [PageSectionTypes.ITEM_LINKS]: ItemLinks,
  [PageSectionTypes.BLOCK_TEXT]: BlockText,
  [PageSectionTypes.ICON_LINKS]: IconLinks,
};
