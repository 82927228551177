import { ServiceTypes } from '~/types';
import { SERVICES } from '../../getServiceDisplayData';
import openWindow from '../../utils/openWindow';

const shouldHandle = (url: string) =>
  SERVICES[ServiceTypes.AUDIOMACK].urlPattern.test(url);

/**
 * Add tracking params that audiomack require as part of partnership deal.
 */
const openAudiomack = (url: string) => {
  if (!shouldHandle(url)) return;

  const parsed = new URL(url);
  const { searchParams } = parsed;

  searchParams.set('utm_source', 'songwhipkey');
  searchParams.set('utm_campaign', 'onelink');
  searchParams.set('utm_medium', 'website');

  return openWindow(parsed.toString());
};

export default openAudiomack;
