import CollapsableText from '~/app/components/CollapsableText';
import Box from '~/app/components/Box';

import { useRegisterNavItem } from '../../ItemPageNav';
import { MAX_CONTENT_WIDTH } from '../../constants';
import { PageSectionComponent } from '../types';
import SectionTitle from '../lib/SectionTitle';
import { BlockTextProps } from './types';
import { resolveText } from './utils';
import { usePageTheme } from '../../ItemPageEdit/addons/theme/PageThemeContext';
import { toRgba } from '~/app/lib/utils/color';

// TODO: rename this TextSection
const BlockText: PageSectionComponent<BlockTextProps> = ({
  title,
  text,
  navTitle = 'About',
  sectionId,
  sectionIndex,
  withShowMore = true,
  layoutData,
}) => {
  const pageTheme = usePageTheme();
  const textResolved = resolveText({ text, layoutData });

  if (!textResolved) {
    return null;
  }

  useRegisterNavItem({
    id: sectionId,
    text: navTitle,
    index: sectionIndex,
  });

  return (
    <Box>
      {title && <SectionTitle margin="0 0 2rem" text={title} />}
      <Box
        padding="0 1.2rem"
        isCentered
        maxWidth={MAX_CONTENT_WIDTH}
        style={{
          boxSizing: 'content-box',
          textAlign: 'center',
        }}
      >
        <CollapsableText
          text={textResolved}
          color={toRgba(pageTheme.textColor, 0.8)}
          margin="0 3"
          textSize="1.7rem"
          lineHeight="2.3rem"
          initialMaxLines={3}
          withShowMore={withShowMore}
          // REVIEW: maybe should be pro only?
          withLinkify={true}
          testId="artistDescription"
        />
      </Box>
    </Box>
  );
};

export default BlockText;
