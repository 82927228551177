import { PageSectionPreset } from '../../types';
import { PageSectionTypes } from '../types';
import { CarouselItem } from './types';

export const toCarouselSectionPreset = ({
  ...props
}: {
  items: CarouselItem[];
}): PageSectionPreset => {
  return {
    component: PageSectionTypes.CAROUSEL,
    props,
  };
};
