import Debug from 'debug';
import navigateWindow from './navigateWindow';

const debug = Debug('songwhip/utils/openNativeUrl');

const openNativeUrl = ({
  url,
  fallback,
  fallbackTimeout = 500,
}: {
  url: string;
  fallback: string;
  fallbackTimeout?: number;
}) =>
  new Promise<void>((resolve) => {
    debug('open', url);

    let pageHidden = false;

    const onHidden = () => (pageHidden = true);
    document.addEventListener('visibilitychange', onHidden);
    addEventListener('pagehide', onHidden);
    addEventListener('blur', onHidden);

    // first try the native url
    const iframe = document.createElement('iframe');
    iframe.style.border = 'none';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.display = 'none';
    iframe.src = url;

    document.body.appendChild(iframe);

    // then redirect to fallback url incase native url fails
    setTimeout(() => {
      document.removeEventListener('visibilitychange', onHidden);
      removeEventListener('pagehide', onHidden);
      removeEventListener('blur', onHidden);

      document.body.removeChild(iframe);

      if (!pageHidden) {
        debug('falling back to %s', fallback);

        // don't use window.open() as this can trigger pop-up blockers as time
        // period between trusted click and opening window can exceed threshold
        navigateWindow(fallback);
      }

      resolve();
    }, fallbackTimeout);
  });

export default openNativeUrl;
