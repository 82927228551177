import { PageSectionPreset } from '../../types';
import { PageSectionTypes } from '../types';
import { VideosSectionItem, VideosSectionProps } from './types';

export const toVideosSectionPreset = (props: {
  title: string;
  navTitle?: string;
  items?: VideosSectionItem[];
}): PageSectionPreset<VideosSectionProps> => {
  return {
    component: PageSectionTypes.VIDEOS,

    props: {
      ...props,
      sectionId: 'videos',
    },
  };
};
