import { selectCustomPageGlobal } from '../customPages/selectors';
import { selectArtistGlobal } from '../artists/selectors';
import { selectAlbumGlobal } from '../albums/selectors';
import { selectTrackGlobal } from '../tracks/selectors';
import { resolveError } from '~/app/lib/errors/utils';
import { ItemTypes } from '~/types';

import { StatusTypes, State } from '../types';

const selectByPath = (state: State, path: string) => state.paths[path];

export const selectPathValue = (state: State, path: string) => {
  const item = state.paths[path];
  return item && item.value;
};

export const createSelectItemByPath = () => {
  return (state: State, path: string) => {
    const value = selectPathValue(state, path);

    if (!value || selectPathIsLoading(state, path)) return;

    switch (value.type) {
      case ItemTypes.ARTIST:
        return selectArtistGlobal(state, value.id);
      case ItemTypes.ALBUM:
        return selectAlbumGlobal(state, value.id);
      case ItemTypes.TRACK:
        return selectTrackGlobal(state, value.id);
      case ItemTypes.CUSTOM_PAGE:
        return selectCustomPageGlobal(state, value.id);
    }
  };
};

export const selectPathIsLoading = (state: State, path: string) => {
  const item = selectByPath(state, path);
  return item && item.status === StatusTypes.PENDING;
};

export const selectPathError = (state: State, path: string) => {
  const item = selectByPath(state, path);
  return resolveError(item?.error);
};

export const selectItemByPathGlobal = createSelectItemByPath();
