import React, { FC } from 'react';

import { toRgba } from '~/app/lib/utils/color';
import Sticky from '~/app/components/Sticky';
import Text from '~/app/components/Text';

import { usePageTheme } from '../../ItemPageEdit/addons/theme/PageThemeContext';

const SectionTitle: FC<{
  text: string | undefined;
  margin?: string;
  padding?: string;
  minHeight?: string | number;
  isSticky?: boolean;
}> = ({ text, minHeight, isSticky = false, ...textProps }) => {
  const pageTheme = usePageTheme();

  return (
    <Sticky
      style={{ minHeight }}
      zIndex={isSticky ? 3 : undefined}
      top="2rem"
      isEnabled={isSticky}
    >
      {text && (
        <Text
          tag="h2"
          size="1.95rem"
          centered
          color={toRgba(pageTheme.textColor, 0.8)}
          shadow={`0 0.01em 0.15em ${toRgba(pageTheme.backgroundColor, 0.6)}`}
          style={{
            userSelect: 'none',
          }}
          {...textProps}
        >
          {text}
        </Text>
      )}
    </Sticky>
  );
};

export default SectionTitle;
