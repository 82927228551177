import { PageSectionPreset } from '../../types';
import { PageSectionTypes } from '../types';
import { MerchSectionProps } from './types';

export const toMerchSectionPreset = (props: {
  title: string;
  navTitle: string;
}): PageSectionPreset<MerchSectionProps> => ({
  component: PageSectionTypes.MERCH,

  props: {
    ...props,
    sectionId: 'merch',
  },
});
