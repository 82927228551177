import { useMemo } from 'react';
import { useEmitter } from '~/app/lib/hooks/useEmitter';
import useOpenExternalService from '~/app/lib/hooks/useOpenExternalService';
import { useAppRouter } from '~/app/lib/router2';

const useOpenLink = () => {
  const openExternalService = useOpenExternalService();
  const emitter = useEmitter();
  const appRouter = useAppRouter();

  return useMemo(
    () =>
      ({
        url,
        pagePeeking,
        onPagePeek,
        name,
      }: {
        url: string;
        pagePeeking?: boolean;
        onPagePeek: (pagePath: string) => void;

        /**
         * Name passed to pixel tracking event to identify the button clicked.
         * This should usually be the button text.
         */
        name: string;
      }) => {
        const isLocal = url.startsWith(location.origin);

        if (isLocal) {
          if (pagePeeking) {
            const { pathname } = new URL(url);
            onPagePeek(pathname);
            return;
          }

          const { pathname, search } = new URL(url);
          appRouter.push(pathname + search);

          // TODO: emit event for pixels to track
          return;
        }

        // emit event so arbitrary parts of the app can respond (eg. fb pixel)
        emitter.emit('openExternalService', {
          name,
          url,
        });

        openExternalService(url);
      },
    []
  );
};

export default useOpenLink;
