import Debug from 'debug';

import {
  isMobileBrowser,
  isFacebookWebView,
  isAndroid,
} from '~/app/lib/device/utils';

import { toSpotifyUtmParamsQuery } from '../../spotify/queryString';
import openNativeUrl from '../../utils/openNativeUrl';
import openWindow from '../../utils/openWindow';
import { toQueryString } from '../../router2';

const SPOTIFY_PATTERN = /open\.spotify\.com/;
const debug = Debug('songwhip/openSpotify');

/**
 * Spotify links are handled on the serverside, this is so that
 * we format and encode the utm tracking params in a secret way.
 */
const openSpotify = ({
  url,
  utmSource,
  utmMedium,
  pagePath,
  withUtmParams,
}: {
  url: string;
  utmMedium?: string;
  utmSource?: string;
  pagePath: string;
  withUtmParams: boolean;
}) => {
  if (!shouldHandle(url)) {
    debug('not using native url');
    return false;
  }

  const openSpotifyProxyUrl = `/api/spotify/open?${toQueryString({ url })}&${
    withUtmParams
      ? toSpotifyUtmParamsQuery(pagePath, { utmSource, utmMedium })
      : ''
  }`;

  if (shouldUseNativeUrl()) {
    return openNativeUrl({
      url: `${openSpotifyProxyUrl}&useNativeProtocol=true`,
      fallback: openSpotifyProxyUrl,
      fallbackTimeout: 1000,
    });
  }

  openWindow(openSpotifyProxyUrl);

  // indicate that it's being handled
  return true;
};

const shouldHandle = (url) => SPOTIFY_PATTERN.test(url);

/**
 * We only only want to open Spotify using the native protocol (spotify://…)
 * on desktop as android/ios auto handle opening native app when a Spotify
 * url is navigated to.
 */
const shouldUseNativeUrl = () => {
  const { userAgent } = navigator;

  // We check for !mobile here to handle the ipad pro case. The ipad pro user-agent
  // pretends to be desktop, but runs mobile safari which opens urls in the same
  // way as ios, this means we should let the OS manage opening native apps.
  const isDesktopDevice = !isMobileBrowser(userAgent);

  if (isDesktopDevice) {
    return true;
  }

  // facebook in-app browser on android fails to launch the native spotify app,
  // so in this case we force it to open using the native protocol
  if (isFacebookWebView(userAgent) && isAndroid(userAgent)) {
    return true;
  }

  return false;
};

export default openSpotify;
