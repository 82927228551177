import { useContext, FC, createContext } from 'react';
import mitt from 'mitt';

import { Emitter } from './types';

// if there is no Provider in the tree then this default value is used
const Context = createContext<Emitter>(mitt());

export const withEmitter = <P,>(Component: FC<P>) => {
  const emitter = mitt();

  const ComponentWithEmitter: FC<P> = (props) => (
    <Context.Provider value={emitter}>
      <Component {...props} />
    </Context.Provider>
  );

  return ComponentWithEmitter;
};

export const useEmitter = () => useContext(Context);
