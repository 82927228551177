import { toQueryString } from '../router2';

export const toSpotifyUtmParamsQuery = (
  pagePath: string,
  { utmSource, utmMedium }: { utmSource?: string; utmMedium?: string }
) => {
  // We're creating a utm campaign string that identifies this Songwhip page.
  // We're not handling the `utm_campaign` query-param that may be present in
  // the url so that this is consistent in the spotify reports. SME may want
  // this format changing, but this is probably fine for first pass.
  const utmCampaign = `songwhip.com${pagePath}`;

  return toQueryString({
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
  });
};
