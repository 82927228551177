import { createContext, FC, ReactNode, useContext } from 'react';
import { isColorLight } from '~/app/lib/utils/color';
import { ItemContext } from '../../../types';

interface PageThemeValue {
  backgroundColor: string;
  textColor: string;
}

const DEFAULT_THEME = {
  backgroundColor: '#000',
  textColor: '#fff',
};

const PageThemeContext = createContext<PageThemeValue>(DEFAULT_THEME);

export const PageThemeProvider: FC<{
  itemContext: ItemContext | undefined;
  children: ReactNode;
}> = ({ itemContext, children }) => {
  const value = {
    ...DEFAULT_THEME,
    ...itemContext?.addons.THEME,
  };

  const textColor = isColorLight(value.backgroundColor) ? '#000' : '#fff';

  return (
    <PageThemeContext.Provider
      value={{
        ...value,
        textColor,
      }}
    >
      {children}
    </PageThemeContext.Provider>
  );
};

export const usePageTheme = () => useContext(PageThemeContext);
